import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { DatePicker, Icon, Input, Select, Upload, Button } from 'antd'
import useUpload from '../../../../hooks/useUpload'
import { getBaseName } from '../../../../utils/getBaseName'
import moment from 'moment'
import Editor from '../../../../shared/Editor'
import { outletSchema } from '../../../../formValidationSchema'
import { addOutlet, editOutlet } from '../../../../services-add-edit/outlet'
import { NotificationManager } from 'react-notifications'
import { useAuth } from '../../../../contexts/auth-context'
import { v4 as uuidv4 } from 'uuid'
import { docsFileAccept } from '../../../../utils/fileTypes'

const FormArticle = ({
  data,
  setShow,
  categoriesF,
  circles,
  getBlocks,
  blocksData,
}) => {
  const [fileDocsList, setFileDocsList] = useState([])
  const [deletedDocs, setDeletedDocs] = useState([])
  const { logout } = useAuth()

  const formik = useFormik({
    initialValues: {},
    validationSchema: outletSchema,
    validateOnMount: false,
    onSubmit: async (values, { setSubmitting }) => {
      // Documents Things-----------
      // Check if the real binary file exists
      const sendingDocs = fileDocsList.filter(
        (cur) => 'File' in window && cur instanceof File
      )

      values.documents = sendingDocs
      values.deleteDocuments = deletedDocs
      // -----------
      
      const a = data?.id
        ? await editOutlet(data.id, values, logout)
        : await addOutlet(values, logout)
      // const a = await addBlog(values);
      if (a) {
        setShow(false)
        NotificationManager.success(
          data?.id ? 'Outlet Edited Successfully' : 'Outlet Added Successfully'
        )
      }
      setSubmitting(false)
    },
  })

  const {
    onChange: onChangeMain,
    onRemove: onRemoveMain,
    beforeUpload: beforeUploadMain,
    fileList: fileListMain,
    setFileList: setFileListMain,
  } = useUpload(1)

  const propsMainImage = {
    listType: 'picture',
    onChange: onChangeMain,
    onRemove: onRemoveMain,
    beforeUpload: beforeUploadMain,
    fileList: fileListMain,
  }

  useEffect(() => {
    console.log(data, 'the-data')
    const initialValues = [
      'title',
      'metaTitle',
      'metaDescription',
      'metaKeywords',
      'publishing_time',
      'description',
      'slug',
    ]
    if (data?.id) {
      initialValues.forEach((cur) => {
        formik.setFieldValue(cur, data[cur])
      })

      let circles = []
      if (data?.circles.length > 0) {
        circles = data?.circles?.map((cur) => cur.id)
      }

      getBlocks(circles)

      let blocks = []
      if (data?.blocks.length > 0) {
        blocks = data?.blocks?.map((cur) => cur.id)
      }

      let categories = [];
      if (data?.categories.length > 0) {
        categories = data?.categories?.map((cur) => cur.id)
      }

      
      formik.setFieldValue('circles', circles)
      formik.setFieldValue('localities', blocks)
      formik.setFieldValue('callNow', data.call_now)
      formik.setFieldValue('whatsappNo', data.whatsapp_no)
      formik.setFieldValue('categories', categories);
      formik.setFieldValue('websiteUrl', data.website_url)
      formik.setFieldValue('mapUrl', data.map_url)

      if (data?.image) {
        const image = [
          {
            uid: '-1',
            url: data.image,
            name: getBaseName(data.image),
            thumbUrl: data.image,
          },
        ]
        setFileListMain(image)
        formik.setFieldValue('image', image ? image : null)
      }

      setFileDocsList([])
      setDeletedDocs([])

      if (data?.documents?.length > 0) {
        let availableDocs = []
        data.documents.forEach((doc) => {
          const availableDoc = {
            uid: uuidv4(),
            name: doc.split('_').pop(),
            status: 'done',
            url: `${process.env.BASE_URL}/${doc}`,
            path: doc,
            // thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          }
          availableDocs.push(availableDoc)
        })

        setFileDocsList(availableDocs)
        availableDocs = []
      }
    }
  }, [data, categoriesF])

  useEffect(() => {
    if (fileListMain) formik.setFieldValue('image', fileListMain)
  }, [fileListMain])

  const propsDocumentUpload = {
    onRemove: (file) => {
      const cloneFileList = [...fileDocsList]
      const index = cloneFileList.indexOf(file)
      const newFileList = cloneFileList.slice()
      newFileList.splice(index, 1)
      setFileDocsList(newFileList)


      if (file.uid) {
        setDeletedDocs((deletedDoc) => [...deletedDoc, file.path])
      }
    },
    beforeUpload: (file, files) => {
      setFileDocsList([...fileDocsList, ...files])
      return false
    },
    fileList: fileDocsList,
    multiple: true,
  }

  const slugOptions = data?.id
    ? [
        {
          type: (
            <Input
              value={formik.values.slug}
              name="slug"
              onChange={formik.handleChange}
            />
          ),
          key: 'slug',
          label: 'Slug',
          error: formik.errors.slug,
        },
      ]
    : null

  let formItems = [
    {     type: (
      <Select
      value={formik.values.categories}
       mode = 'multiple'
       name="categories"
        placeholder="Select Categories"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          return (
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }}
          onChange={(e) => formik.setFieldValue('categories', e)}
      >
        {categoriesF?.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {`${i.title}`}
            </Select.Option>
          ))}
    </Select>          
),
    key: 'categories',
      label: 'Product Title',
      error: formik.errors.categories,
},
{     type: (
            <Select
               value={formik.values.circles}
               mode = 'multiple'
               name="circles"
                placeholder="Select Circles"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  console.log(input, option, 'gyhighi');
                  return (
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={(e) => {
                  formik.setFieldValue('circles', e)
                  getBlocks(e)
                }}
              >
                {circles?.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {`${i.name}`}
            </Select.Option>
          ))}
            </Select>          
),
key: 'circles',
  label: 'Select Circles',
  error: formik.errors.circles,
},
{     type: (
  <Select
  
  value={formik.values.localities}
   mode = 'multiple'
   name="localities"
    placeholder="Select Localities"
    showSearch
    optionFilterProp="children"
    filterOption={(input, option) => {
      return (
        option.props.children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }}
    onChange={(e) => formik.setFieldValue('localities', e)}
  >
   {blocksData?.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {`${i.name}`}
            </Select.Option>
          ))}
</Select>          
),
key: 'localities',
label: 'Select Localities',
error: formik.errors.localities,
},
    
    {
      type: (
        <Input
          value={formik.values.title}
          name="title"
          onChange={formik.handleChange}
        />
      ),
      key: 'title',
      label: 'Outlet Title',
      error: formik.errors.title,
    },
    {
      label: 'Image',
      error: formik.errors.image,
      key: 'image',
      name: 'image',
      type: (
        <>
          <Upload listType="picture-card" name="image" {...propsMainImage}>
            <Button>
              <Icon type="upload" /> Select File
            </Button>
          </Upload>
          <small>The image size should be 340px*240px</small>
        </>
      ),
    },
    {
      type: (
        <Editor
          placeholder="Write something..."
          editorHtml={formik.values.description || ''}
          onChange={(e) => formik.setFieldValue('description', e)}
        />
      ),
      label: 'Description',
      error: formik.errors.description,
      key: 'description',
    },
    {
      type: (
        <Input
          value={formik.values.callNow}
          name="callNow"
          placeholder="Eg: +919876543210"
          onChange={formik.handleChange}
        />
      ),
      key: 'callNow',
      label: 'Call Now Number',
      error: formik.errors.callNow,
    },
    {
      type: (
        <Input
          value={formik.values.whatsappNo}
          placeholder="Eg: +919876543210"
          name="whatsappNo"
          onChange={formik.handleChange}
        />
      ),
      key: 'whatsappNo',
      label: 'WhatsApp API',
      error: formik.errors.whatsappNo,
    },

    {
      type: (
        <Input
          value={formik.values.mapUrl}
          name="mapUrl"
          onChange={formik.handleChange}
        />
      ),
      key: 'mapUrl',
      label: 'Map Url',
      error: formik.errors.mapUrl,
    },
    {
      type: (
        <Input
          value={formik.values.websiteUrl}
          name="websiteUrl"
          onChange={formik.handleChange}
        />
      ),
      key: 'websiteUrl',
      label: 'Website',
      error: formik.errors.websiteUrl,
    },
    {
      type: (
        <Input
          value={formik.values.metaTitle}
          name="metaTitle"
          onChange={formik.handleChange}
        />
      ),
      key: 'metaTitle',
      label: 'Meta Title',
      error: formik.errors.metaTitle,
    },
    {
      type: (
        <Input
          value={formik.values.metaDescription}
          onChange={formik.handleChange}
          name="metaDescription"
        />
      ),
      key: 'metaDescription',
      label: 'Meta Description',
      error: formik.errors.metaDescription,
    },
    {
      type: (
        <Input
          value={formik.values.metaKeywords}
          onChange={formik.handleChange}
          name="metaKeywords"
        />
      ),
      key: 'metaKeywords',
      label: 'Meta keywords',
      error: formik.errors.metaKeywords,
    },
    {
      type: (
        <Upload {...propsDocumentUpload} accept={docsFileAccept}>
          <Button>
            <Icon type="upload" /> Select Docs
          </Button>
        </Upload>
      ),
      key: 'documents',
      label: 'Documents',
    },
    slugOptions ? slugOptions[0] : null,
  ]

  return (
    <form onSubmit={formik.handleSubmit} className="mt-2 w-100">
      {/* <div className="form-group"> */}

      {formItems?.map((item) => {
        return (
          <div className="form-group">
            <label style={{ display: 'block', marginBottom: 10 }}>
              {item?.label}
            </label>
            {item?.type}
            <div className="error-msg d-block">
              {item?.error && item?.error}
            </div>
          </div>
        )
      })}

      <div className="d-flex-hr-center">
        <button
          type="submit"
          disabled={formik.isSubmitting}
          className="primary-btn"
        >
          Submit
        </button>
      </div>
    </form>
  )
}

export default FormArticle
