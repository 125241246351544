import React, { useRef, useEffect, useState } from 'react'
import Header from '../../Header'
import Hero from './Hero'
import About from './About'
import Pages from './Pages'
import Services from '../services/Services'
import Categories from '../categories/Categories'
import Editions from '../editions/Editions'
import Footer from '../../Footer'
import Modal from './Modal'
import { homePageData } from '../api'
import { Helmet } from 'react-helmet'
import { DatePicker } from 'antd'
import { useLocation } from 'react-router-dom'

const Home = () => {
  const [state, setState] = React.useState([])
  const { hash } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const refScroller = (data) => {
    const section = document.querySelector(`#${data}`)
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  useEffect(() => {
    if (hash) {
      const hashVal = hash.split('#')[1]
      document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
          refScroller(hashVal)
        }
      }
    }
  }, [hash])

  React.useEffect(() => {
    handleHomePageData()
  }, [])

  const handleHomePageData = async () => {
    let res = await homePageData()
    setState(res)
  }

  useEffect(() => {
    const location = window.local
  }, [])

  return (
    <>
      <Header refScroller={refScroller} data={state} />

      {state.keywords &&
        state.keywords.map((data, j) => (
          <Helmet>
            <title>{data.page_title}</title>
            <meta name="og:title" content={data.page_title} />
            <meta
              name="twitter:title"
              property="og:title"
              content={data.page_title}
            />
            <meta name="twitter:description" content={data.page_Description} />
            <meta name="og:description" content={data.page_Description} />
            <meta name="description" content={data.page_Description} />
            <meta name="keywords" content={data.page_keywords} />
          </Helmet>
        ))}
      {state.alert !== null && state.alert !== undefined && (
        <Modal state={state.alert} />
      )}
      <Hero state={state.banner} alert={state.alert} />
      <Services />
  
      <Pages />
  

      <About state={state.about} />
      <Footer />
    </>
  )
}
export default Home
