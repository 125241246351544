import React, { useState, useEffect } from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import { getArticlesBySlug, homePageData } from '../api'
import noimage from '../../img/No-Photo-Available.jpg'
import { Link } from 'react-router-dom'
import HeroImg from '../../img/article.jpg'
import { Helmet } from 'react-helmet'
import { NotFound } from '../404Error'
import { useData } from '../../contexts/Datacontext'
import TextTruncate from 'react-text-truncate'

const ArticleList = (props) => {
  const [page, setPage] = useState({})
  const [blogs, setBlogs] = useState([])
  const [id, setId] = useState('')
  const { data } = useData()

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchArticlesBySlug()
  }, [])

  const fetchArticlesBySlug = async () => {
    try {
      setId(props.match.params.id)
      const response = await getArticlesBySlug(props.match.params.id)
      setPage(response)
      setBlogs(response.blogs)
    } catch (err) {
      console.log('errors', err)
    }
  }

  useEffect(() => {
    handlePageData()
  }, [])

  const handlePageData = async () => {
    const res = await homePageData()
    //const favicon = document.getElementById('favicon')
    //if (favicon) favicon.href = res?.logo?.Logo
  }

  const refScroller = (data) => {
    if (props.location.pathname === '/home') {
      const section = document.querySelector(`#${data}`)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      props.history.push({
        pathname: '/',
        state: { detail: data },
      })
    }
  }

  function stripHtml(html) {
    var temporalDivElement = document.createElement('div')
    temporalDivElement.innerHTML = html
    let content =
      temporalDivElement.textContent || temporalDivElement.innerText || ''
    return content
  }
  const createMarkup = (data) => {
    return { __html: data }
  }

  const handleSearchChange = (evt) => {
    const searchResult = page?.blogs?.filter((blog) =>
      blog?.title?.toLowerCase().includes(evt.target.value)
    )
    setBlogs(searchResult)
  }

  return (
    <>
      {Object.keys(page) ? (
        <div>
          <Header refScroller={refScroller} data={data?.data} />
          {page.publishing_Page && (
            <Helmet>
              <title>{page.publishing_Page.metaTitle}</title>
              <meta name="og:title" content={page.publishing_Page.metaTitle} />
              <meta
                name="twitter:title"
                property="og:title"
                content={page.publishing_Page.metaTitle}
              />
              <meta
                name="twitter:description"
                content={page.publishing_Page.metaDescription}
              />
              <meta
                name="og:description"
                content={page.publishing_Page.metaDescription}
              />
              <meta
                name="description"
                content={page.publishing_Page.metaDescription}
              />
              <meta
                name="keywords"
                content={page.publishing_Page.metaKeywords}
              />
            </Helmet>
          )}
          <div className="waxon_tm_blog_single">
            <div className="hero classic">
              <div
                className="articleImg"
                style={{ backgroundImage: `url(${HeroImg})` }}
              />
            </div>
            <div>
              <input
                onChange={handleSearchChange}
                style={{
                  color: 'black',
                  width: '400px',
                  backgroundColor: '#f5f8fc',
                  float: 'right',
                  borderRadius: '50px',
                }}
                type="text"
                placeholder="Search.."
              />
            </div>
          </div>
          <div className="waxon_tm_news" id="news">
            <div className="container">
              {page.publishing_Page && (
                <div className="waxon_tm_main_title">
                  <div className="title" style={{ paddingTop: '20px' }}>
                    <h3>
                      {page.publishing_Page.title}
                      <span className="bg">Features</span>
                    </h3>
                    <div
                      style={{ paddingTop: '50px' }}
                      dangerouslySetInnerHTML={createMarkup(
                        page?.publishing_Page?.content
                      )}
                    />
                  </div>
                </div>
              )}

              <div className="news_inner">
                <ul>
                  {blogs?.length > 0 ? (
                    blogs.map((blog, j) => (
                      <>
                        <li
                          className="wow fadeInDown"
                          data-wow-duration="0.8s"
                          data-wow-delay="0.4s"
                          key={j}
                        >
                          <div className="list_inner">
                            <div className="image">
                              {blog.photo ? (
                                <>
                                  <img
                                    src={blog.photo}
                                    alt="main"
                                    style={{ width: '450px', height: '260px' }}
                                  />
                                  <div
                                    className="main"
                                    style={{
                                      backgroundImage: `url(${blog.photo})`,
                                      width: '100%',
                                      height: '260px',
                                    }}
                                  ></div>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={noimage}
                                    alt="blank"
                                    style={{
                                      width: '450px',
                                      height: '260px',
                                      backgroundImage: `url(${noimage})`,
                                    }}
                                  />
                                </>
                              )}
                            </div>
                            <div className="details">
                              <h3 className="title" style={{ fontSize: 12 }}>
                                {page?.publishing_Page?.title}
                              </h3>
                              <h3 className="title">
                                <Link
                                  to={{
                                    pathname: `article/${blog.slug}`,
                                    state: id,
                                    search: id,
                                  }}
                                >
                                  <TextTruncate
                                    line={1}
                                    element="span"
                                    truncateText="…"
                                    text={stripHtml(blog.title)}
                                  />
                                  {/* {.title} */}
                                </Link>
                              </h3>
                              <div></div>
                              <div>
                                <div>
                                  {/* {stripHtml(blog.content) + '...'} */}
                                  <TextTruncate
                                    line={2}
                                    element="span"
                                    truncateText="…"
                                    text={stripHtml(blog.content)}
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: 'auto' }}>
                                <Link
                                  style={{
                                    float: 'right',
                                    backgroundColor: '#4ab7ff',
                                    padding: '10px',
                                    border: 'none',
                                    borderRadius: '10px',
                                    margin: '10px',
                                    color: '#fff',
                                  }}
                                  to={{
                                    pathname: `/article/${blog.slug}`,
                                    state: id,
                                    search: id,
                                  }}
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    ))
                  ) : (
                    <div className="row">
                      <div className="col d-flex justify-content-center align-items-center">
                        <h3 style={{ fontWeight: 'bold' }}>No Feature Found</h3>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <NotFound />
      )}
    </>
  )
}
export default ArticleList
