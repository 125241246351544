import React, { useState, useEffect } from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import {
  getServicesBySlug,
  homePageData,
  getCirclesData,
  getBlocksData,
} from '../api'
import noimage from '../../img/No-Photo-Available.jpg'
import { Link, useLocation } from 'react-router-dom'
import HeroImg from '../../img/article.jpg'
import { Helmet } from 'react-helmet'
import { NotFound } from '../404Error'
import { useData } from '../../contexts/Datacontext'
import { Select } from 'antd'
import queryString from 'query-string'
import TextTruncate from 'react-text-truncate'

const Service = (props) => {
  const { Option } = Select
  const [page, setPage] = useState({})
  const [experts, setExperts] = useState([])
  const [circles, setCircles] = useState([])
  const [selectedCircleId, setSelectedCircleId] = useState('')
  const [selectedCircle, setSelectedCircle] = useState('')

  const [blocks, setBlocks] = useState([])
  const [selectedBlockId, setSelectedBlockId] = useState('')
  const [selectedBlock, setSelectedBlock] = useState('')

  const [id, setId] = useState('')
  const { data } = useData()

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchCirclesData()
    // fetchBlocksData()
    // fetchServicesBySlug('');
  }, [id])


  const { search } = useLocation()

  let parsed = search.replace('?', '')
  const { circleName, blockName } = queryString.parse(parsed)

  // On page load if we have ?CircleName
  useEffect(() => {
    const getCircle = circles.filter(
      (cur) => cur.name === (circleName ? circleName : '')
    )

    // We are assigning selected circle Id
    setSelectedCircleId(getCircle[0]?.id ? getCircle[0]?.id : '')

    getCircle[0]?.id && fetchBlocksData(`circles[]=${getCircle[0]?.id}`)
    // This value is used for dataList value
    setSelectedCircle(circleName ? circleName : '')
  }, [circleName, circles])

  // On page load if we have ?BlockName
  useEffect(() => {
    const getBlock = blocks.filter(
      (cur) => cur.name === (blockName ? blockName : '')
    )

    // We are assigning selected circle Id
    setSelectedBlockId(getBlock[0]?.id ? getBlock[0]?.id : '')

    // This value is used for dataList value
    setSelectedBlock(blockName ? blockName : '')
  }, [blockName, blocks])

  useEffect(() => {
    handlePageData()
  }, [])

  useEffect(() => {
    console.log('Selected', selectedCircle)
    // fetchServicesBySlug(selectedCircleId)
    if (selectedCircle && selectedBlock) {
      fetchServicesBySlug(selectedCircleId, selectedBlockId)
      props.history.replace(
        `/service/${props.match.params.id}?circleName=${selectedCircle}&blockName=${selectedBlock}`
      )
    } else if (selectedCircle) {
      fetchServicesBySlug(selectedCircleId)
      props.history.replace(
        `/service/${props.match.params.id}?circleName=${selectedCircle}`
      )
    } else if (selectedBlock) {
      fetchServicesBySlug(selectedBlockId)
      props.history.replace(
        `/service/${props.match.params.id}?blockName=${selectedBlock}`
      )
    } else {
      fetchServicesBySlug()
    }

    // if (selectedCircle) {
    //   props.history.replace(
    //     `/service/${props.match.params.id}?circleName=${selectedCircle}`
    //   )
    // }
  }, [selectedCircleId, selectedBlockId])

  const handlePageData = async () => {
    const res = await homePageData()
    //const favicon = document.getElementById('favicon')
    //if (favicon) favicon.href = res?.logo?.Logo
  }

  const fetchServicesBySlug = async (selectedCircleId, selectedBlockId) => {
    
    try {
      setId(props.match.params.id)
      const response = await getServicesBySlug(
        props.match.params.id,
        selectedCircleId ? selectedCircleId : null,
        selectedBlockId ? selectedBlockId : null
      )

      setPage(response)
      setExperts(
        response?.published_data?.Experts.filter(
          (expert) =>
            expert.status === 'active' && expert.createdBy.status === 'active'
        )
      )
    } catch (err) {
      console.log('errors', err)
    }
  }

  const fetchCirclesData = async () => {
    try {
      const response = await getCirclesData()
      setCircles(response)
    } catch (err) {
      console.log(err)
    }
  }

  const fetchBlocksData = async (query) => {
    try {
      const response = await getBlocksData(query)
      setBlocks(response)
    } catch (err) {
      console.log(err)
    }
  }


  const refScroller = (data) => {
    if (props.location.pathname === '/home') {
      const section = document.querySelector(`#${data}`)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      props.history.push({
        pathname: '/',
        state: { detail: data },
      })
    }
  }

  function stripHtml(html) {
    var temporalDivElement = document.createElement('div')
    temporalDivElement.innerHTML = html
    let content =
      temporalDivElement.textContent || temporalDivElement.innerText || ''
    return content.slice(0, 100)
  }

  const createMarkup = (data) => {
    return { __html: data }
  }

  const handleSearchChange = (evt) => {
    const searchResult = page?.published_data?.experts.filter(
      (expert) =>
        expert?.title?.toLowerCase().includes(evt.target.value.toLowerCase()) &&
        expert.status === 'active' &&
        expert.createdBy.status === 'active'
    )
    setExperts(searchResult)
  }

  const onChangeCircle = (e) => {
    // // Data list <Tag> is not providing the right values we want

    if ((e === 'All' || e === '') && selectedBlock) {
      props.history.replace(
        `/service/${props.match.params.id}`
        // ?blockName=${selectedBlock}
      )
    } else if ((e === 'All' || e === '') && !selectedBlock) {
      props.history.replace(`/service/${props.match.params.id}`)
      fetchBlocksData()
    } else {
      const getCircle = circles.filter((cur) => cur.id === e)

      // // We are assigning selected circle Id
      // setSelectedCircleId(getCircle[0]?.id ? getCircle[0]?.id : '')

      // This value is used for dataList value

      fetchBlocksData(`circles[]=${e}`)
      setSelectedBlock(null)

      setSelectedCircleId(e)

      setSelectedCircle(getCircle[0]?.name)
    }
  }

  const onChangeBlock = (e) => {
    // // Data list <Tag> is not providing the right values we want

    if ((e === 'All' || e === '') && selectedCircle) {
      props.history.replace(
        `/service/${props.match.params.id}?circleName=${selectedCircle}`
      )
    } else if ((e === 'All' || e === '') && !selectedCircle) {
      props.history.replace(`/service/${props.match.params.id}`)
    } else {
      const getBlock = blocks.filter((cur) => cur.id === e)

      // // We are assigning selected circle Id
      // setSelectedCircleId(getBlock[0]?.id ? getBlock[0]?.id : '')

      // This value is used for dataList value
      setSelectedBlockId(e)

      setSelectedBlock(getBlock[0]?.name)
    }
  }

  
  return (
    <>
      {page && Object.keys(page) ? (
        <div>
          <Header refScroller={refScroller} data={data?.data} />

          {page.published_data && (
            <Helmet>
              <title>{page.published_data.metaTitle}</title>
              <meta name="og:title" content={page.published_data.metaTitle} />
              <meta
                name="twitter:title"
                property="og:title"
                content={page.published_data.metaTitle}
              />
              <meta
                name="twitter:description"
                content={page.published_data.metaDescription}
              />
              <meta
                name="og:description"
                content={page.published_data.metaDescription}
              />
              <meta
                name="description"
                content={page.published_data.metaDescription}
              />
              <meta
                name="keywords"
                content={page.published_data.metaKeywords}
              />
            </Helmet>
          )}
          <div className="waxon_tm_blog_single">
            <div className="hero classic">
              <div
                className="articleImg"
                style={{ backgroundImage: `url(${HeroImg})` }}
              />
            </div>
            <div
              style={{
                float: 'right',
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 15,
                marginBottom: 45,
              }}
            >
              {/* <div style={{ marginRight: 20, marginBottom: 10 }}>
                <input
                  list="circles"
                  name="circle"
                  placeholder="Select Circle"
                  value={selectedCircle}
                  onChange={(e) => onChangeCircle(e)}
                  style={{
                    color: 'black',
                    width: '300px',
                    backgroundColor: '#f5f8fc',
                    // float: "right",
                    borderRadius: '50px',
                    border: 'none',
                    padding: 8,
                    paddingLeft: 10,
                  }}
                />
                <datalist id="circles">
                  <option data-value="">All</option>
                  {circles?.length > 0 &&
                    circles.map((cur) => <option value={cur.name} />)}
                </datalist>
              </div> */}
              <div className="custom-select-antd">
                <label>Circle</label>
                <br />
                <Select
                  showSearch
                  style={{ width: 300 }}
                  placeholder="Select a Circle"
                  optionFilterProp="children"
                  onChange={onChangeCircle}
                  value={selectedCircleId}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="All">All</Option>
                  {circles?.map((cur) => (
                    <Option value={cur.id}>{cur.name}</Option>
                  ))}
                </Select>
              </div>

              <div className="custom-select-antd">
                <label>Locality</label>
                <br />
                <Select
                  showSearch
                  style={{ width: 300 }}
                  placeholder="Select a Locality"
                  optionFilterProp="children"
                  onChange={onChangeBlock}
                  value={selectedBlockId}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="All">All</Option>
                  {blocks?.map((cur) => (
                    <Option value={cur.id}>{cur.name}</Option>
                  ))}
                </Select>
              </div>

              <input
                onChange={handleSearchChange}
                // style={{
                //   color: "black",
                //   width: "350px",
                //   backgroundColor: "#f5f8fc",
                //   // float: "right",
                //   borderRadius: "50px",
                // }}
                style={{ marginTop: 34 }}
                className="searchbar-homes"
                type="text"
                placeholder="Search Lawyers"
              />
            </div>
          </div>

          <div className="waxon_tm_news" id="news">
            <div className="container">
              {page.published_data && (
                <div className="waxon_tm_main_title">
                  <div className="title" style={{ paddingTop: '30px' }}>
                    <h3>
                      {page.published_data.title}
                      <span className="bg">Lawyers</span>
                    </h3>
                    <div
                      // style={{ paddingTop: "50px" }}
                      dangerouslySetInnerHTML={createMarkup(
                        page?.published_data?.description
                      )}
                    />
                  </div>
                </div>
              )}

              <div className="news_inner">
                <ul>
                  {experts?.length > 0 ? (
                    experts.map((expert, index) => (
                      <>
                        <li
                          className="wow fadeInDown"
                          data-wow-duration="0.8s"
                          data-wow-delay="0.4s"
                          key={index}
                        >
                          <div className="list_inner">
                            <div className="image">
                              {expert.image ? (
                                <>
                                  <img
                                    src={`https://avjuris.com/${expert.image}`}
                                    alt="main"
                                    style={{ width: '100%', height: '260px' }}
                                  />
                                  <div
                                    className="main"
                                    style={{
                                      backgroundImage: `url(${expert.image})`,
                                      width: '100%',
                                      height: '260px',
                                    }}
                                  ></div>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={noimage}
                                    alt="blank"
                                    style={{
                                      width: '100%',
                                      height: '260px',
                                      backgroundImage: `url(${noimage})`,
                                    }}
                                  />
                                </>
                              )}
                            </div>
                            <div className="details">
                              <h3 className="title" style={{ fontSize: 12 }}>
                                {page?.published_data?.title}
                              </h3>
                              <h3 className="title">
                                <TextTruncate
                                  line={1}
                                  element="span"
                                  truncateText="…"
                                  text={expert.title}
                                />
                              </h3>
                              <div></div>
                              <div>
                                <div>
                                  <TextTruncate
                                    line={2}
                                    element="span"
                                    truncateText="…"
                                    text={stripHtml(expert.description)}
                                  />
                                </div>
                              </div>
                              <div className="mt-1">
                                {/* <p>
                                  <small>
                                    <i
                                      class="fa fa-phone fa-lg"
                                      saria-hidden="true"
                                    ></i>
                                    <b class="mx-2">Call Now</b>
                                    {expert.call_now}
                                  </small>
                                </p>
                                <p>
                                  <small>
                                    <i
                                      class="fa fa-whatsapp my-float"
                                      saria-hidden="true"
                                    ></i>
                                    <b class="mx-2">Whatsapp</b>
                                    {expert.whatsapp_no}
                                  </small>
                                </p>  */}
                              </div>
                              {/* <div> */}
                              {/* <a
                                  style={{
                                    float: "right",
                                    backgroundColor: "#4ab7ff",
                                    padding: "10px",
                                    border: "none",
                                    borderRadius: "10px",
                                    margin: "10px",
                                    color: "#fff",
                                  }}
                                  rel="noopener noreferrer"
                                  href={`${expert.map_url}`}
                                  target="_blank"
                                >
                                  View Direction
                                </a> */}
                              <div
                                className="action-btns"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  className="call-align"
                                  style={{
                                    textAlign: 'center',
                                    backgroundColor: '#4ab7ff',
                                    padding: '4px',
                                    border: 'none',
                                    borderRadius: '10px',
                                    color: '#fff',
                                    width: '110px',
                                    height: '50px',
                                    lineHeight: '40px',
                                  }}
                                >
                                  <a
                                    href={`tel://${expert.call_now}`}
                                    style={{
                                      color: 'white',
                                      textDecoration: 'none',
                                    }}
                                  >
                                    <i
                                      class="fa fa-phone fa-lg"
                                      saria-hidden="true"
                                    ></i>{' '}
                                    Call Us
                                  </a>
                                </div>
                                {expert?.whatsapp_no && (
                                  <div
                                    style={{
                                      backgroundColor: '#25D366',
                                      padding: '10px',
                                      border: 'none',
                                      borderRadius: '10px',
                                      cursor: 'pointer',
                                      margin: '10px 2px',
                                      color: '#fff',
                                      textAlign: 'center',
                                      width: '110px',
                                      height: '50px',
                                    }}
                                  >
                                    <a
                                      href={`https://api.whatsapp.com/send?phone=${expert.whatsapp_no}&amp;text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202.`}
                                      style={{
                                        color: 'white',
                                        textDecoration: 'none',
                                      }}
                                    >
                                      WhatsApp
                                    </a>
                                  </div>
                                )}

                                <div
                                  style={{
                                    backgroundColor: '#4ab7ff',
                                    padding: '10px',
                                    border: 'none',
                                    borderRadius: '10px',
                                    margin: '10px 2px',
                                    textAlign: 'center',
                                    width: '110px',
                                    height: '50px',
                                  }}
                                >
                                  <Link
                                    style={{ color: '#fff' }}
                                    to={{
                                      pathname: `/experts/${expert.slug}`,
                                      state: id,
                                      search: id,
                                    }}
                                  >
                                    More &gt;&gt;
                                  </Link>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </li>
                      </>
                    ))
                  ) : (
                    <div className="row">
                      <div className="col d-flex justify-content-center align-items-center">
                        <h3 style={{ fontWeight: 'bold' }}>No Lawyers Found</h3>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      ) : (
        <NotFound />
      )}
    </>
  )
}
export default Service
