import React, { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik'
import { DatePicker, Icon, Input, Select, Upload, Button, Form } from 'antd'
import useUpload from '../../../../hooks/useUpload'
import { getBaseName } from '../../../../utils/getBaseName'
import moment from 'moment'
import Editor from '../../../../shared/Editor'
// import { articleSchema } from "../../../../formValidationSchema";
// import { addBlog, editBlog } from "../../../../services-add-edit/article";
import { NotificationManager } from 'react-notifications'
import { useAuth } from '../../../../contexts/auth-context'
import { addExpert, editExpert } from '../../../../services-add-edit/expert'
import { expertSchema } from '../../../../formValidationSchema'
import { v4 as uuidv4 } from 'uuid'
import { docsFileAccept } from '../../../../utils/fileTypes'

const FormArticle = ({
  data,
  servicesF,
  circleData,
  setShow,
  getBlocks,
  blocksData,
}) => {
  const [fileDocsList, setFileDocsList] = useState([])
  const [deletedDocs, setDeletedDocs] = useState([])
  const { logout } = useAuth()
  const tref = useRef();
  const { Option } = Select;

  console.log(servicesF);

  const formik = useFormik({
    initialValues: {
      publishedDate: new Date().toISOString(),
    },
    validationSchema: expertSchema,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values, { setSubmitting }) => {
      // Documents Things-----------
      // Check if the real binary file exists
      const sendingDocs = fileDocsList.filter(
        (cur) => 'File' in window && cur instanceof File
      )

      values.documents = sendingDocs
      values.deleteDocuments = deletedDocs
      // -----------

      const a = data?.id
        ? await editExpert(data.id, values, logout)
        : await addExpert(values, logout)
      // const a = await addBlog(values);
      if (a) {
        setShow(false)
        NotificationManager.success(
          data?.id ? 'Lawyer Edited Successfully' : 'Lawyer Added Successfully'
        )
      }
      setSubmitting(false)
    },
  })

  const {
    onChange: onChangeMain,
    onRemove: onRemoveMain,
    beforeUpload: beforeUploadMain,
    fileList: fileListMain,
    setFileList: setFileListMain,
  } = useUpload(1)

  const propsMainImage = {
    listType: 'picture',
    onChange: onChangeMain,
    onRemove: onRemoveMain,
    beforeUpload: beforeUploadMain,
    fileList: fileListMain,
  }

  useEffect(() => {
  
    const initialValues = [
      'title',
      'metaTitle',
      'metaDescription',
      'metaKeywords',
      'call_now',
      'whatsapp_no',
      'map_url',
      'website_url',
      'slug',
      'description',
    ]
    if (data?.id) {
      initialValues.forEach((cur) => {
        formik.setFieldValue(cur, data[cur])
      })

      let activeCircles = []
      if (data?.circles.length > 0) {
        activeCircles = data?.circles?.map((cur) => cur.id)
      }

      getBlocks(activeCircles)

      let blocks = []
      if (data?.blocks.length > 0) {
        blocks = data?.blocks?.map((cur) => cur.id)
      }

      let services = []
      if (data?.services.length > 0) {
        services = data?.services?.map((cur) => {
          if(cur.status === 'active') {
            return cur.id;
          }
          })
      }


      formik.setFieldValue('circles', activeCircles)
      formik.setFieldValue('localities', blocks)
      formik.setFieldValue(
        'services', services    
        /* data.service?.status === 'active' ? data.service?.id : null */
      )
      formik.setFieldValue('callNow', data.call_now)
      formik.setFieldValue('whatsappNo', data.whatsapp_no)
      formik.setFieldValue('mapUrl', data.map_url)
      formik.setFieldValue('websiteUrl', data.website_url)

      if (data?.image) {
        const image = [
          {
            uid: '-1',
            url: data.image,
            name: getBaseName(data.image),
            thumbUrl: data.image,
          },
        ]
        setFileListMain(image)
        formik.setFieldValue('image', image ? image : null)
      }

      setFileDocsList([])
      setDeletedDocs([])

      if (data?.documents?.length > 0) {
        let availableDocs = []
        data.documents.forEach((doc) => {
          const availableDoc = {
            uid: uuidv4(),
            name: doc.split('_').pop(),
            status: 'done',
            url: `${process.env.BASE_URL}/${doc}`,
            path: doc,
            // thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          }
          availableDocs.push(availableDoc)
        })

        setFileDocsList(availableDocs)
        availableDocs = []
      }
    }
  }, [data, servicesF])

  useEffect(() => {
    if (fileListMain) formik.setFieldValue('image', fileListMain)
  }, [fileListMain])

  const propsDocumentUpload = {
    onRemove: (file) => {
      const cloneFileList = [...fileDocsList]
      const index = cloneFileList.indexOf(file)
      const newFileList = cloneFileList.slice()
      newFileList.splice(index, 1)
      setFileDocsList(newFileList)

      if (file.uid) {
        setDeletedDocs((deletedDoc) => [...deletedDoc, file.path])
      }
    },
    beforeUpload: (file, files) => {
      setFileDocsList([...fileDocsList, ...files])
      return false
    },
    fileList: fileDocsList,
    multiple: true,
  }

  const servicesHandler = e => {
    formik.setFieldValue('services', e)
  }

  const circlesHandler = e => {
    formik.setFieldValue('circles', e)
    getBlocks(e)
  }

  const localitiesHandler = e => {
    formik.setFieldValue('localities', e)
  }

  const slugOptions = data?.id
    ? [
        {
          type: (
            <Input
              value={formik.values.slug}
              name="slug"
              onChange={formik.handleChange}
            />
          ),
          key: 'slug',
          label: 'Slug',
          error: formik.errors.slug,
        },
      ]
    : null

  let formItems = [
    {     type: (
                  <Select
                   value={formik.values.services}
                   mode = 'multiple'
                   name="services"
                    placeholder="Select Practice Areas"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      console.log(input, option, 'gyhighi');
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={e => {
                      servicesHandler(e)
                    }} 
                  >
                    {servicesF?.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {`${i.title}`}
            </Select.Option>
          ))}
                </Select>          
    ),
      key: 'services',
      label: 'Practice Area',
      error: formik.errors.services,
  },
    {     type: (
      <Select
                   value={formik.values.circles}
                   mode = 'multiple'
                   name="circles"
                    placeholder="Select Circles"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      console.log(input, option, 'gyhighi');
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={e => {
                      circlesHandler(e)
                    }} 
                  >
                    {circleData?.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {`${i.name}`}
            </Select.Option>
          ))}
                </Select>          
),
key: 'circles',
      label: 'Select Circles',
      error: formik.errors.circles,
},
    {     type: (
      <Select
      
      value={formik.values.localities}
       mode = 'multiple'
       name="localities"
        placeholder="Select Localities"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          console.log(input, option, 'gyhighi');
          return (
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }}
        onChange={e => {
          localitiesHandler(e)
        }} 
      >
        {blocksData?.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {`${i.name}`}
</Select.Option>
))}
    </Select>          
),
key: 'localities',
label: 'Select Localities',
error: formik.errors.localities,
},
    {
      type: (
        <Input
          value={formik.values.title}
          name="title"
          onChange={formik.handleChange}
        />
      ),
      key: 'title',
      label: ' Expert Title',
      error: formik.errors.title,
    },
    {
      label: 'Image',
      error: formik.errors.image,
      key: 'image',
      name: 'image',
      type: (
        <>
          <Upload listType="picture-card" name="image" {...propsMainImage}>
            <Button>
              <Icon type="upload" /> Select File
            </Button>
          </Upload>
          <small>The image size should be 340px*240px</small>
        </>
      ),
    },
    {
      type: (
        <Editor
          placeholder="Write something..."
          editorHtml={formik.values.description || ''}
          onChange={(e) => formik.setFieldValue('description', e)}
        />
      ),
      label: 'Description',
      error: formik.errors.description,
      key: 'description',
    },
    // <Select
    //     mode="multiple"
    //     size={size}
    //     placeholder="Please select"
    //     defaultValue={['a10', 'c12']}
    //     onChange={handleChange}
    //     style={{ width: '100%' }}
    //   >
    //     {children}
    //   </Select>
    {
      type: (
        <Input
          value={formik.values.callNow}
          placeholder="Eg: +919876543210"
          name="callNow"
          onChange={formik.handleChange}
        />
      ),
      key: 'callNow',
      label: 'Call Now Number',
      error: formik.errors.callNow,
    },
    {
      type: (
        <Input
          value={formik.values.whatsappNo}
          placeholder="Eg: +919876543210"
          name="whatsappNo"
          onChange={formik.handleChange}
        />
      ),
      key: 'whatsappNo',
      label: 'WhatsApp API',
      error: formik.errors.whatsappNo,
    },
    {
      type: (
        <Input
          value={formik.values.mapUrl}
          name="mapUrl"
          onChange={formik.handleChange}
        />
      ),
      key: 'mapUrl',
      label: 'Map Url',
      error: formik.errors.mapUrl,
    },
    {
      type: (
        <Input
          value={formik.values.websiteUrl}
          name="websiteUrl"
          onChange={formik.handleChange}
        />
      ),
      key: 'websiteUrl',
      label: 'Website',
      error: formik.errors.websiteUrl,
    },

    {
      type: (
        <Input
          value={formik.values.metaTitle}
          name="metaTitle"
          onChange={formik.handleChange}
        />
      ),
      key: 'metaTitle',
      label: 'Meta Title',
      error: formik.errors.metaTitle,
    },
    {
      type: (
        <Input
          value={formik.values.metaDescription}
          name="metaDescription"
          onChange={formik.handleChange}
        />
      ),
      key: 'metaDescription',
      label: 'Meta Description',
      error: formik.errors.metaDescription,
    },
    {
      type: (
        <Input
          value={formik.values.metaKeywords}
          name="metaKeywords"
          onChange={formik.handleChange}
        />
      ),
      key: 'metaKeywords',
      label: 'Meta Keywords',
      error: formik.errors.metaKeywords,
    },
    {
      type: (
        <Upload {...propsDocumentUpload} accept={docsFileAccept}>
          <Button>
            <Icon type="upload" /> Select Docs
          </Button>
        </Upload>
      ),
      key: 'documents',
      label: 'Documents',
    },
    slugOptions ? slugOptions[0] : null,
  ]

  //   let formItems = [
  //     // { heading: 'General' },
  //     {
  //       type: (
  //         <Select
  //           // mode="multiple"
  //           value={formik.values?.publishing_Page}
  //           name="publishing_Page"
  //           placeholder="Select Publishing Page"
  //           onChange={(e) => formik.setFieldValue("publishing_Page", e)}
  //         >
  //           {services?.map((i) => (
  //             <Select.Option key={i.id} value={i.id}>
  //               {`${i.title}`}
  //             </Select.Option>
  //           ))}
  //         </Select>
  //       ),
  //       name: "publishing_Page",
  //       key: "publishing_Page",
  //       label: "Publishing Page",
  //       error: formik.errors?.publishing_Page,
  //     },
  //     {
  //       type: (
  //         <Input
  //           value={formik.values?.title}
  //           name="title"
  //           onChange={formik.handleChange}
  //         />
  //       ),
  //       key: "title",
  //       label: "Article Title",
  //       error: formik.errors?.title,
  //     },
  //     {
  //       type: (
  //         <DatePicker
  //           showTime={{ format: "HH:mm" }}
  //           format={dateFormat}
  //           value={moment(formik.values?.publishedDate)}
  //           onChange={(e) =>
  //             formik.setFieldValue("publishedDate", new Date(e).toISOString())
  //           }
  //         />
  //       ),
  //       key: "publishedDate",
  //       label: "Publishing Time",
  //       error: formik.errors?.publishedDate,
  //     },
  //     {
  //       type: (
  //         <Input
  //           value={formik.values?.location}
  //           name="location"
  //           onChange={formik.handleChange}
  //         />
  //       ),
  //       key: "location",
  //       label: "Location",
  //       error: formik.errors?.location,
  //     },
  //     {
  //       type: (
  //         <Input
  //           value={formik.values?.author}
  //           name="author"
  //           onChange={formik.handleChange}
  //         />
  //       ),
  //       key: "author",
  //       label: "Author Name",
  //       error: formik.errors?.author,
  //     },
  //     {
  //       label: "photo",
  //       error: formik.errors?.photo,
  //       key: "photo",
  //       name: "photo",
  //       type: (
  //         <>
  //           <Upload listType="picture" name="photo" {...propsMainImage}>
  //             {/* <Button onBlur={(e) => onBlur(e, 'image')}> */}
  //             <Button>
  //               <Icon type="upload" /> Select File
  //             </Button>
  //           </Upload>
  //           <small>The image size should be 340px*240px</small>
  //         </>
  //       ),
  //     },
  //     {
  //       type: (
  //         <Input
  //           value={formik.values?.youtubeurl}
  //           name="youtubeurl"
  //           onChange={formik.handleChange}
  //         />
  //       ),
  //       key: "youtubeurl",
  //       label: "YouTube Url",
  //       error: formik.errors?.youtubeurl,
  //     },
  //     {
  //       type: (
  //         <Editor
  //           placeholder="Write something..."
  //           editorHtml={formik.values.content || ""}
  //           onChange={(e) => formik.setFieldValue("content", e)}
  //         />
  //       ),
  //       label: "Content",
  //       error: formik.errors.content,
  //       key: "content",
  //     },
  //     {
  //       type: (
  //         <Input
  //           value={formik.values?.language}
  //           name="language"
  //           onChange={formik.handleChange}
  //         />
  //       ),
  //       key: "language",
  //       label: "Language",
  //       error: formik.errors?.language,
  //     },
  //     // {
  //     //   type: (
  //     //     <InputNumber
  //     //       onChange={(e) => formik.setFieldValue("priorityOrder", e)}
  //     //       name="priorityOrder"
  //     //       value={formik.values?.priorityOrder}
  //     //       min={1}
  //     //     />
  //     //   ),
  //     //   key: "priorityOrder",
  //     //   label: "Order ",
  //     //   error: formik.errors?.priorityOrder,
  //     // },
  //     {
  //       type: (
  //         <Input
  //           value={formik.values?.metaTitle}
  //           name="metaTitle"
  //           onChange={formik.handleChange}
  //         />
  //       ),
  //       key: "metaTitle",
  //       label: "Meta Title",
  //       error: formik.errors?.metaTitle,
  //     },
  //     {
  //       type: (
  //         <Input
  //           value={formik.values?.metaDescription}
  //           name="metaDescription"
  //           onChange={formik.handleChange}
  //         />
  //       ),
  //       key: "metaDescription",
  //       label: "Meta Description",
  //       error: formik.errors?.metaDescription,
  //     },
  //     {
  //       type: (
  //         <Input
  //           value={formik.values?.metaKeywords}
  //           name="metaKeywords"
  //           onChange={formik.handleChange}
  //         />
  //       ),
  //       key: "metaKeywords",
  //       label: "Meta Keywords",
  //       error: formik.errors?.metaKeywords,
  //     },
  //   ];
  //   const slugOptions = data?.id
  //     ? [
  //         {
  //           type: (
  //             <Input
  //               value={formik.values?.slug}
  //               onChange={formik.handleChange}
  //               name="slug"
  //             />
  //           ),
  //           key: "slug",
  //           label: "Slug",
  //           error: formik.errors?.slug,
  //         },
  //       ]
  //     : null;

  //   if (data?.id) {
  //     formItems = slugOptions ? [...formItems, ...slugOptions] : [...formItems];
  //   }

  return (
    <form onSubmit={formik.handleSubmit} className="mt-2 w-100">
      {/* <div className="form-group"> */}

      {formItems?.map((item) => {
        return (
          <div className="form-group">
            <label style={{ display: 'block', marginBottom: 10 }}>
              {item?.label}
            </label>
            {item?.type}
            <div className="error-msg d-block">
              {item?.error && item?.error}
            </div>
          </div>
        )
      })}

      <div className="d-flex-hr-center">
        <button
          type="submit"
          disabled={formik.isSubmitting}
          className="primary-btn"
        >
          Submit
        </button>
      </div>
    </form>
  )
}

export default FormArticle
