import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { DatePicker, Icon, Input, Select, Upload, Button, message } from 'antd'
import useUpload from '../../../../hooks/useUpload'
import { getBaseName } from '../../../../utils/getBaseName'
import moment from 'moment'
import Editor from '../../../../shared/Editor'
import { articleSchema } from '../../../../formValidationSchema'
import { addBlog, editBlog } from '../../../../services-add-edit/article'
import { NotificationManager } from 'react-notifications'
import { useAuth } from '../../../../contexts/auth-context'
import { UploadOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { docsFileAccept } from '../../../../utils/fileTypes'

const FormArticle = ({ data, pages, setShow }) => {
  const [fileDocsList, setFileDocsList] = useState([
    // {
    //   uid: Date.now(),
    //   name: "xxx.png",
    //   status: "done",
    //   url:
    //     "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    //   // thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
  ])
  const [deletedDocs, setDeletedDocs] = useState([])

  const { logout } = useAuth()

  const formik = useFormik({
    initialValues: {
      publishedDate: new Date().toISOString(),
    },
    validationSchema: articleSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // Documents Things-----------
      // Check if the real binary file exists
      const sendingDocs = fileDocsList.filter(
        (cur) => 'File' in window && cur instanceof File
      )

      values.documents = sendingDocs
      values.deleteDocuments = deletedDocs
      // -----------

      console.log('show values', values)

      const a = data?.id
        ? await editBlog(data.id, values, logout)
        : await addBlog(values, logout)
      // const a = await addBlog(values);
      if (a) {
        setShow(false)
        NotificationManager.success(
          data?.id
            ? 'Feature Edited Successfully'
            : 'Feature Added Successfully'
        )
      }
      setSubmitting(false)
    },
  })
  const dateFormat = 'DD/MM/YYYY/ h:mm:ss a'

  const {
    onChange: onChangeMain,
    onRemove: onRemoveMain,
    beforeUpload: beforeUploadMain,
    fileList: fileListMain,
    setFileList: setFileListMain,
  } = useUpload(1)

  const propsMainImage = {
    listType: 'picture',
    onChange: onChangeMain,
    onRemove: onRemoveMain,
    beforeUpload: beforeUploadMain,
    fileList: fileListMain,
  }

  useEffect(() => {
    console.log(data, 'the-data')
    const initialValues = [
      'title',
      'publishedDate',
      'photo',
      'view_type',
      'youtubeurl',
      'content',
      'publishing_Page',
      'metaTitle',
      'metaDescription',
      'metaKeywords',
      'status',
      'location',
      'author',
      'language',
      'slug',
    ]
    if (data?.id) {
      const isIdAvailable = pages?.find(
        (cur) => cur.id == data?.publishing_Page.id
      )
      initialValues.forEach((cur) => {
        formik.setFieldValue(cur, data[cur])
      })

      formik.setFieldValue(
        'publishing_Page',
        isIdAvailable ? data.publishing_Page.id : null
      )

      if (data?.photo) {
        const image = [
          {
            uid: '-1',
            url: data.photo,
            name: getBaseName(data.photo),
            thumbUrl: data.photo,
          },
        ]
        setFileListMain(image)
        formik.setFieldValue('photo', image ? image : null)
      }

      setFileDocsList([])
      setDeletedDocs([])

      if (data?.documents?.length > 0) {
        let availableDocs = []
        data.documents.forEach((doc) => {
          const availableDoc = {
            uid: uuidv4(),
            name: doc.split('_').pop(),
            status: 'done',
            url: `${process.env.BASE_URL}/${doc}`,
            path: doc,
            // thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          }
          availableDocs.push(availableDoc)
        })

        console.log(availableDocs, 'heee')
        setFileDocsList(availableDocs)
        availableDocs = []
      }
    }
  }, [data, pages])

  useEffect(() => {
    if (fileListMain) formik.setFieldValue('photo', fileListMain)
  }, [fileListMain])

  const propsDocumentUpload = {
    onRemove: (file) => {
      const cloneFileList = [...fileDocsList]
      const index = cloneFileList.indexOf(file)
      const newFileList = cloneFileList.slice()
      newFileList.splice(index, 1)
      setFileDocsList(newFileList)


      if (file.uid) {
        setDeletedDocs((deletedDoc) => [...deletedDoc, file.path])
      }
    },
    beforeUpload: (file, files) => {
      setFileDocsList([...fileDocsList, ...files])
      return false
    },
    fileList: fileDocsList,
    multiple: true,
  }

  let formItems = [
    // { heading: 'General' },
    {
      type: (
        <Select
          // mode="multiple"
          value={formik.values?.publishing_Page}
          name="publishing_Page"
          placeholder="Select Publishing Page"
          onChange={(e) => formik.setFieldValue('publishing_Page', e)}
        >
          {pages?.map((i) => (
            <Select.Option key={i.id} value={i.id}>
              {`${i.title}`}
            </Select.Option>
          ))}
        </Select>
      ),
      name: 'publishing_Page',
      key: 'publishing_Page',
      label: 'Publishing Page',
      error: formik.errors?.publishing_Page,
    },
    {
      type: (
        <Input
          value={formik.values?.title}
          name="title"
          onChange={formik.handleChange}
        />
      ),
      key: 'title',
      label: 'Feature Title',
      error: formik.errors?.title,
    },
    {
      type: (
        <DatePicker
          style={{ width: '100%' }}
          showTime={{ format: 'HH:mm' }}
          format={dateFormat}
          value={moment(formik.values?.publishedDate)}
          onChange={(e) =>
            formik.setFieldValue('publishedDate', new Date(e).toISOString())
          }
        />
      ),
      key: 'publishedDate',
      label: 'Publishing Time',
      error: formik.errors?.publishedDate,
    },
    {
      type: (
        <Input
          value={formik.values?.location}
          name="location"
          onChange={formik.handleChange}
        />
      ),
      key: 'location',
      label: 'Location',
      error: formik.errors?.location,
    },
    {
      type: (
        <Input
          value={formik.values?.author}
          name="author"
          onChange={formik.handleChange}
        />
      ),
      key: 'author',
      label: 'Author Name',
      error: formik.errors?.author,
    },
    {
      label: 'photo',
      error: formik.errors?.photo,
      key: 'photo',
      name: 'photo',
      type: (
        <>
          <Upload listType="picture" name="photo" {...propsMainImage}>
            {/* <Button onBlur={(e) => onBlur(e, 'image')}> */}
            <Button>
              <Icon type="upload" /> Select Files
            </Button>
          </Upload>
          <small>The image size should be 340px*240px</small>
        </>
      ),
    },
    {
      type: (
        <Input
          value={formik.values?.youtubeurl}
          name="youtubeurl"
          onChange={formik.handleChange}
        />
      ),
      key: 'youtubeurl',
      label: 'YouTube Url',
      error: formik.errors?.youtubeurl,
    },
    {
      type: (
        <Editor
          placeholder="Write something..."
          editorHtml={formik.values.content || ''}
          onChange={(e) => formik.setFieldValue('content', e)}
        />
      ),
      label: 'Content',
      error: formik.errors.content,
      key: 'content',
    },
    {
      type: (
        <Input
          value={formik.values?.language}
          name="language"
          onChange={formik.handleChange}
        />
      ),
      key: 'language',
      label: 'Language',
      error: formik.errors?.language,
    },
    // {
    //   type: (
    //     <InputNumber
    //       onChange={(e) => formik.setFieldValue("priorityOrder", e)}
    //       name="priorityOrder"
    //       value={formik.values?.priorityOrder}
    //       min={1}
    //     />
    //   ),
    //   key: "priorityOrder",
    //   label: "Order ",
    //   error: formik.errors?.priorityOrder,
    // },
    {
      type: (
        <Input
          value={formik.values?.metaTitle}
          name="metaTitle"
          onChange={formik.handleChange}
        />
      ),
      key: 'metaTitle',
      label: 'Meta Title',
      error: formik.errors?.metaTitle,
    },
    {
      type: (
        <Input
          value={formik.values?.metaDescription}
          name="metaDescription"
          onChange={formik.handleChange}
        />
      ),
      key: 'metaDescription',
      label: 'Meta Description',
      error: formik.errors?.metaDescription,
    },
    {
      type: (
        <Input
          value={formik.values?.metaKeywords}
          name="metaKeywords"
          onChange={formik.handleChange}
        />
      ),
      key: 'metaKeywords',
      label: 'Meta Keywords',
      error: formik.errors?.metaKeywords,
    },
    {
      type: (
        <Upload {...propsDocumentUpload} accept={docsFileAccept}>
          <Button>
            <Icon type="upload" /> Select Docs
          </Button>
        </Upload>
      ),
      key: 'documents',
      label: 'Documents',
    },
  ]
  const slugOptions = data?.id
    ? [
        {
          type: (
            <Input
              value={formik.values?.slug}
              onChange={formik.handleChange}
              name="slug"
            />
          ),
          key: 'slug',
          label: 'Slug',
          error: formik.errors?.slug,
        },
      ]
    : null

  if (data?.id) {
    formItems = slugOptions ? [...formItems, ...slugOptions] : [...formItems]
  }

  console.log(fileDocsList, 'gettt')

  return (
    <form onSubmit={formik.handleSubmit} className="mt-2 w-100">
      {/* <div className="form-group"> */}

      {formItems?.map((item) => {
        return (
          <div className="form-group">
            <label style={{ display: 'block', marginBottom: 10 }}>
              {item.label}
            </label>
            {item.type}
            <div className="error-msg d-block">{item.error && item.error}</div>
          </div>
        )
      })}

      <div className="d-flex-hr-center">
        <button
          type="submit"
          disabled={formik.isSubmitting}
          className="primary-btn"
        >
          Submit
        </button>
      </div>
    </form>
  )
}

export default FormArticle
