import { Popconfirm, Tag } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import noimage from '../../img/No-Photo-Available.jpg'

function stripHtml(html) {
  var temporalDivElement = document.createElement('div')
  temporalDivElement.innerHTML = html
  let content =
    temporalDivElement.textContent || temporalDivElement.innerText || ''
  return content.slice(0, 100)
}

const Card = ({
  data,
  i,
  setId,
  setModal,
  onDelete,
  containerName,
  dataName,
  serviceName,
  setPreviewId,
  setOpenPreviewModal,
}) => {
  return (
    <li
      className="wow fadeInDown"
      data-wow-duration="0.8s"
      data-wow-delay="0.4s"
      key={i}
      style={{ width: 'auto', paddingLeft: 0, marginTop: 24 }}
    >
      <div
        className="list_inner"
        style={{
          justifyContent: 'center',
          minHeight: '431px',
        }}
      >
        <div className="image" style={{ marginTop: '-57px' }}>
          {data.photo || data.image ? (
            <>
              <img
                src={data.photo || data.image}
                alt="main"
                style={{ width: '100%', height: '260px' }}
              />
              <div
                className="main"
                style={{
                  backgroundImage: `url(${data.photo || data.image})`,
                  minWidth: '100%',
                  // width: "100%",
                  objectFit: 'contain',
                  height: '260px',
                }}
              ></div>
            </>
          ) : (
            <>
              <img
                src={noimage}
                alt="blank"
                style={{
                  width: '100%',
                  height: '260px',
                  backgroundImage: `url(${noimage})`,
                }}
              />
            </>
          )}
        </div>
        <div className="details">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3 className="title" style={{ fontSize: 12 }}>
              {data?.publishing_Page?.title}
            </h3>
            <h3 style={{ fontSize: 12 }}>
              <Tag color={data?.status === 'active' ? 'green' : 'red'}>
                {data?.status}
              </Tag>
            </h3>
          </div>
          <h3 className="title">
            <Link
              onClick={() => {
                setPreviewId(data.id)
                setOpenPreviewModal(true)
              }}
              //   to={{
              //     pathname: `${dataName}/${data.slug}`,
              //     state: serviceName
              //       ? `${serviceName}-${data[containerName].title}`
              //       : data[containerName].title,
              //     search: serviceName
              //       ? `${serviceName}-${data[containerName].title}`
              //       : data[containerName].title,
              //   }}
            >
              {data.title}
            </Link>
          </h3>
          <div></div>
          <div>
            <div>
              {stripHtml(data?.content ? data.content : data?.description) +
                '...'}
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 'auto',
            }}
          >
            <button
              type="button"
              style={{
                // clear: "both",
                // float: "right",
                backgroundColor: '#4ab7ff',
                padding: '10px',
                border: 'none',
                borderRadius: '10px',
                width: '100%',
                // margin: "10px",
                color: '#fff',
                textAlign: 'center',
              }}
              onClick={() => {
                setPreviewId(data.id)
                setOpenPreviewModal(true)
              }}
              //   to={{
              //     pathname: `/${dataName}/${data.slug}`,
              //     state: serviceName
              //       ? `${serviceName}-${data[containerName].title}`
              //       : data[containerName].title,
              //     search: serviceName
              //       ? `${serviceName}-${data[containerName].title}`
              //       : data[containerName].title,
              //   }}
            >
              View
            </button>
            <Popconfirm
              title="Are you sure to delete this item?"
              onConfirm={() => onDelete(data?.id)}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <button
                type="button"
                style={{
                  // clear: "both",
                  // float: "right",
                  backgroundColor: '#4ab7ff',
                  padding: '10px',
                  width: '100%',
                  border: 'none',
                  borderRadius: '10px',
                  // margin: "10px",
                  background: '#dc3545',
                  color: '#fff',
                  margin: '0 10px',
                }}
                // to={{
                //   pathname: `/article/${article.slug}`,
                //   state: article?.publishing_Page?.title,
                //   search: article?.publishing_Page?.title,
                // }}
              >
                Delete
              </button>
            </Popconfirm>

            <button
              type="button"
              style={{
                // clear: "both",
                // float: "right",
                backgroundColor: '#4ab7ff',
                padding: '10px',
                border: 'none',
                width: '100%',
                borderRadius: '10px',
                // margin: "10px",
                color: '#fff',
              }}
              onClick={() => {
                setId(data.id)
                setModal(true)
              }}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </li>
  )
}

export default Card
