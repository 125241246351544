import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import noimage from '../../img/No-Photo-Available.jpg'
import { homePageData, servicesPageData } from '../api'
import { useData } from '../../contexts/Datacontext'
import Header from '../../Header'
import Footer from '../../Footer'
import { notification } from 'antd'
import TextTruncate from 'react-text-truncate'

const Services = (props) => {
  const [services, setServices] = useState([])
  const [unFilterData, setUnFilterData] = useState([])
  const size = 10
  const history = useHistory()
  const { data } = useData()
  const isServices = history.location.pathname === '/services'
  
  const fetchHomePageData = async () => {
    const res = await homePageData()
    const activeServices = res?.services
    setUnFilterData(activeServices)
    setServices(activeServices)
    ///update favicon and title
    //const favicon = document.getElementById('favicon')
    //if (favicon) {
     // favicon.href = res?.logo?.Logo
    //}
  }

  const handleServicesPageData = async () => {
    const data = await servicesPageData ();
    const activeServices = data.data
    setUnFilterData(activeServices)
    setServices(activeServices)
  }

  useEffect(() => {
     if(!isServices){
      fetchHomePageData()
    }
    if(isServices){
      handleServicesPageData()
    } 
  }, [isServices])


  const refScroller = (data) => {
    if (props.location.pathname === '/home') {
      const section = document.querySelector(`#${data}`)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      props.history.push({
        pathname: '/',
        state: { detail: data },
      })
    }
  }

  const handlePages = (data) => {
    history.push(`/service/${data}`)
  }

  const createMarkup = (html) => {
    var temporalDivElement = document.createElement('div')
    temporalDivElement.innerHTML = html
    let content =
      temporalDivElement.textContent || temporalDivElement.innerText || ''
    return content
    // return { __html: data }
  }

  const handleSearchChange = (evt) => {
    const searchResult = unFilterData?.filter((service) =>
      service?.title?.toLowerCase().includes(evt.target.value.toLowerCase())
    )
    setServices(searchResult)
  }

  return (
    <div>
      {isServices && <Header refScroller={refScroller} data={data?.data} />}
      <div className="waxon_tm_news mt-10" id="services" ref={props.refProp}>
        <div className="container">
          <div className="waxon_tm_main_title">
            <div className="title" style={{ paddingTop: 20 }}>
              <h3>
                {' '}
                Practice Areas & Lawyers<span className="bg">Practice Areas & Lawyers</span>
              </h3>
            </div>
          </div>
         {isServices && <div style={{ paddingTop: '10px' }}>
            <input
              className="searchbar-homes"
              onChange={handleSearchChange}
              type="text"
              placeholder="Search for the Practice Area .."
            />
          </div>}
          <div className="news_inner">
            <ul>
              {services?.map((service, index) => (
                <li className="wow fadeInDown" data-wow-duration="0.8s">
                  <Fade top>
                    <div className="list_inner" key={index}>
                      <div className="image">
                        {service.image ===
                        'https://avjuris.com/null' ? (
                          <>
                            <img
                              onClick={() => handlePages(service.slug)}
                              src={noimage}
                              alt="no"
                              style={{
                                width: '100%',
                                height: '260px',
                                backgroundImage: `url(${noimage})`,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              onClick={() => handlePages(service.slug)}
                              src={service.image}
                              alt="cover"
                              style={{ width: '100%', height: '260px' }}
                            />
                            <div
                              className="main"
                              onClick={() => handlePages(service.slug)}
                              style={{
                                backgroundImage: `url(${service.image})`,
                                width: '100%',
                                height: '260px',
                              }}
                            />
                          </>
                        )}
                      </div>
                      <div className="details">
                        <h3 className="title">
                          <a onClick={() => handlePages(service.slug)}>
                            <TextTruncate
                              line={1}
                              element="span"
                              truncateText="…"
                              text={service.title}
                            />
                          </a>
                        </h3>
                        <div>
                          <div>
                            <TextTruncate
                              line={2}
                              element="div"
                              truncateText="…"
                              text={createMarkup(service?.description)}
                            />

                            {/* {service.description?.length > 100 ? (
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  service.description
                                    .replace(
                                      /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                                      ''
                                    )
                                    
                                )}
                              />
                            ) : (
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  service.description
                                )}
                              />
                            )} */}
                          </div>
                        </div>
                        <div
                          className="waxon_tm_read_more text-right"
                          style={{ marginTop: 'auto' }}
                        >
                          <button
                            style={{
                              backgroundColor: '#4ab7ff',
                              padding: '10px',
                              border: 'none',
                              borderRadius: '10px',
                              margin: '10px',
                              color: '#fff',
                            }}
                            onClick={() => handlePages(service.slug)}
                          >
                            Show
                          </button>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </li>
              ))}
              {history.location.pathname !== '/services' &&
                services.length > 6 && (
                  <div className="waxon_tm_read_more text-center">
                    <button
                      style={{
                        backgroundColor: '#32CD32',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '10px',
                        margin: '10px',
                        color: '#fff',
                      }}
                      onClick={() => history.push('/services')}
                    >
                      View All
                    </button>
                  </div>
                )}
              {!services.length && (
                <div>
                  <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                      <h3 style={{ fontWeight: 'bold' }}>No Service Found</h3>
                    </div>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      {isServices && <Footer />}
    </div>
  )
}

export default Services
