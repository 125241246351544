import React, { useEffect, useState } from "react";

import Header from "../../../Header";
import Footer from "../../../Footer";
import noimage from "../../../img/No-Photo-Available.jpg";
// import { Link } from "react-router-dom";
import HeroImg from "../../../img/article.jpg";
// import { Helmet } from "react-helmet";
// import { NotFound } from "../404Error";

import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useData } from "../../../contexts/Datacontext";
import AddEditExpert from "./add-edit";
import { Link } from "react-router-dom";
import authErrorChecker from "../../../utils/authErrorChecker";
import errorValidator from "../../../utils/errorValidator";
import { useAuth } from "../../../contexts/auth-context";
import { Popconfirm } from "antd";
import { NotificationManager } from "react-notifications";
import {
  viewAllExpert,
  deleteExpertApi,
  viewExpert,
} from "../../../api/expert";
import Card from "../../../shared/Card";
import CustomModal from "../../../shared/Modal";
import PreviewPage from "../../../shared/PreviewPage/experts-outlets";

const UserExpert = () => {
  const { logout } = useAuth();

  const { data } = useData();
  const [expertData, setExpertData] = useState([]);
  const [expertId, setExpertId] = useState(null);
  const [addEditExpertModal, setAddEditExpertModal] = useState(false);

  // Preview Functionality states
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expertPreviewData, setExpertPreviewData] = useState(null);
  const [expertPreviewId, setExpertPreviewId] = useState(null);

  const refScroller = (data) => {
    console.log("scrol-data", data);
    const section = document.querySelector(`#${data}`);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  function stripHtml(html) {
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    let content =
      temporalDivElement.textContent || temporalDivElement.innerText || "";
    return content.slice(0, 100);
  }

  const getAllExpert = async () => {
    try {
      const resp = await viewAllExpert();
      console.log(resp.data, "the-resp");
      setExpertData(resp.data.data);
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err?.response?.data || err?.response);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getAllExpert();

    // fetchServicesBySlug('');
  }, [addEditExpertModal]);

  const onDelete = async (expertId) => {
    try {
      const resp = await deleteExpertApi(expertId);

      if (resp.data.success) {
        const restExpertData = [...expertData].filter(
          (expert) => expert.id !== expertId
        );
        setExpertData(restExpertData);
        NotificationManager.success("Lawyer Deleted Successfully");
      }
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err?.response?.data || err.response);
    }
  };

  // --------------------Preview Functionality----------------------------

  const fetchExpertById = async (expertId) => {
    setLoading(true);
    try {
      const res = await viewExpert(expertId);
      setExpertPreviewData(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout);

      // Additional Error checker
      errorValidator(err.response.data || err.response);
    }
  };

  useEffect(() => {
    if (openPreviewModal) {
      if (expertPreviewId) {
        fetchExpertById(expertPreviewId);
      }
    }
  }, [expertPreviewId, openPreviewModal]);

  // --------------------Preview Functionality end----------------------------

  // const handleSearchChange = () => {

  //   const searchResult = articleData.filter(
  //     (expert) =>
  //       expert?.title?.toLowerCase().includes(evt.target.value.toLowerCase()) &&
  //       expert.status === "active"
  //   );
  //   setExperts(searchResult);
  // };

  return (
    <div>
      <Header refScroller={refScroller} data={data?.data} />

      <div className="waxon_tm_blog_single">
        <div className="hero classic" style={{ marginBottom: 40 }}>
          <div
            className="articleImg"
            style={{ backgroundImage: `url(${HeroImg})` }}
          />
        </div>

        <Container>
          <div className="waxon_tm_main_title">
            <div className="title" style={{ paddingTop: "30px" }}>
              <h3>
                Lawyer
                <span className="bg">Lawyer</span>
              </h3>

              {/* <div
            // style={{ paddingTop: "50px" }}
            dangerouslySetInnerHTML={createMarkup(
              page?.published_data?.description
            )}
          /> */}
            </div>
          </div>
          <div
            className="add-icon-container"
            onClick={() => setAddEditExpertModal(true)}
          >
            <i className="fa fa-plus-circle add-icon"></i>
          </div>
          <AddEditExpert
            id={expertId}
            setShow={setAddEditExpertModal}
            show={addEditExpertModal}
            setExpertId={setExpertId}
          />

          {/* Preview Page*/}
          <CustomModal
            title="Preview Page"
            size="xl"
            setShow={setOpenPreviewModal}
            show={openPreviewModal}
          >
            <div style={{ minHeight: 400 }}>
              {loading ? (
                <div className="element-centering-position">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                  </Spinner>
                </div>
              ) : (
                expertPreviewData && (
                  <PreviewPage pageData={expertPreviewData} />
                )
              )}
            </div>
          </CustomModal>

          <div className="waxon_tm_news" style={{ marginBottom: 40 }}>
            <div className="news_inner">
              {/* <Row>
                  <Col>1 of 2</Col>
                  <Col>2 of 2</Col>
                </Row> */}
              <Row>
                {expertData?.length > 0 ? (
                  expertData.map((expert, j) => (
                    <Col lg="4" md="12" sm="12" xs="12">
                      <Card
                        data={expert}
                        i={j}
                        setId={setExpertId}
                        setModal={setAddEditExpertModal}
                        onDelete={onDelete}
                        containerName="service"
                        dataName="experts"
                        serviceName="services"
                        setPreviewId={setExpertPreviewId}
                        setOpenPreviewModal={setOpenPreviewModal}
                      />
                    </Col>
                  ))
                ) : (
                  <div className="row">
                    <div className="col d-flex justify-content-center align-items-center">
                      <h3 style={{ fontWeight: "bold" }}>No Lawyers Found</h3>
                    </div>
                  </div>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default UserExpert;
