/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { viewAllCircles } from '../../../../api/circles'

import { viewAllServices, viewExpert } from '../../../../api/expert'
import { useAuth } from '../../../../contexts/auth-context'
import CustomModal from '../../../../shared/Modal'
import authErrorChecker from '../../../../utils/authErrorChecker'
import errorValidator from '../../../../utils/errorValidator'
import FormExpert from './Form'
import { viewAllBlocks } from '../../../../api/blocks'

const AddEditArticle = ({ id, setExpertId, setShow, show }) => {
  const { logout } = useAuth()

  const [servicesF, setServicesF] = useState([])
  const [circles, setCircles] = useState([])
  const [blocksData, setBlocksData] = useState([])
  const [expert, setExpert] = useState(null)
  const [loading, setLoading] = useState(false)

  //   Fetching pages
  const fetchingServices = async () => {
    try {
      const res = await viewAllServices()
      setServicesF(res.data.data)
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout)

      // Additional Error checker
      errorValidator(err.response.data || err.response)
    }
  }

  //   Fetching Circles
  const fetchingCircles = async () => {
    try {
      const res = await viewAllCircles()
      setCircles(res.data.data)
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout)

      // Additional Error checker
      errorValidator(err.response.data || err.response)
    }
  }

  // Fetch Article by Id
  const fetchExpertById = async (expertId) => {
    setLoading(true)
    try {
      const res = await viewExpert(expertId)
      setExpert(res.data.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)

      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout)

      // Additional Error checker
      errorValidator(err.response.data || err.response)
    }
  }

  const getBlocks = async (circleIds) => {
    const paramsCircles = queryString.stringify(
      { circles: circleIds },
      { arrayFormat: 'bracket' }
    )

    try {
      const res = await viewAllBlocks(paramsCircles)
      if (res.data.data) {
        setBlocksData(res.data.data)
      } else {
        setBlocksData([])
      }
    } catch (err) {
      // 403 HOLD, 401 -> error catcher
      authErrorChecker(err, logout)
      setBlocksData([])

      // Additional Error checker
      errorValidator(err.response.data || err.response)
    }
  }

  useEffect(() => {
    if (show) {
      fetchingServices()
      fetchingCircles()

      if (id) {
        fetchExpertById(id)
      }
    } else {
      setExpert(null)
      setExpertId(null)
    }
  }, [show, id])

  return (
    <CustomModal
      title={expert ? 'Edit Lawyer' : 'Add Lawyer'}
      size="lg"
      setShow={setShow}
      show={show}
    >
      <div style={{ minHeight: 400 }}>
        {loading ? (
          <div className="element-centering-position">
            <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : expert?.id ? (
          <FormExpert
            setShow={setShow}
            data={expert}
            servicesF={servicesF}
            circleData={circles}
            getBlocks={getBlocks}
            blocksData={blocksData}
          />
        ) : (
          <FormExpert
            setShow={setShow}
            servicesF={servicesF}
            circleData={circles}
            getBlocks={getBlocks}
            blocksData={blocksData}
          />
        )}
      </div>
    </CustomModal>
  )
}

export default AddEditArticle
