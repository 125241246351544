import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

// import Recaptcha from "react-recaptcha";
const SITE_KEY = '6Lc_bWMmAAAAAGdSK-33iboeAf2uNScIt0vnlI-V'

const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [phone, setPhone] = useState('')
  const [uploadFile, setUploadFile] = React.useState([])
  const [submit, setSubmit] = useState(false)
  const [error, setError] = useState([])

  const fileSelected = (doc) => {
    setUploadFile(doc)
    const file =
      document.getElementById('filePicker').files[
        document.getElementById('filePicker').files.length - 1
      ]
    document.getElementById('fileName').innerHTML = file.name
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    fetch('http://139.59.92.109:5001/api/backend/v1/sendmail', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      response.json().then((response) => {
        if (this.state.isVerified) {
          alert('Message Sent.')
          this.resetForm()
        } else {
          alert('Message failed to send.')
        }
      })
    })
  }

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL

    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function (res) {
        console.log(res)
      }
    )
  }, [])

  const handleOnClick = (e) => {
    e.preventDefault()
    if (
      name.trim().length === 0 ||
      email.trim().length === 0 ||
      phone.trim().length === 0
    ) {
      setSubmit(true)
      return false
    }
    setLoading(true)
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: 'submit' })
        .then((token) => {
          submitData(token)
        })
    })
  }

  const resetForm = () => {
    setName('')
    setEmail('')
    setMessage('')
    setPhone('')
    setSubmit(false)
    setError([])
    document.querySelector('input[type=file]').value = ''
    document.getElementById('fileName').innerHTML = ''
  }

  const submitData = (token) => {
    // call a backend API to verify reCAPTCHA response
    const myFile = document.querySelector('input[type=file]').files[0]
    const data = new FormData()
    data.append('file', myFile)
    data.append('name', name)
    data.append('email', email)
    if (message.trim().length > 0) {
      data.append('message', message)
    }
    data.append('phone', phone)
    data.append('g_recaptcha_response', token)
    fetch('/api/backend/v1/contact_request/add', {
      method: 'POST',
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        if (res.success) {
          setResponse(res)
          resetForm()
          alert('Message Sent')
        } else {
          setError(res.error.split('\n'))
        }
      })
      .catch((e) => {
        console.log(e)
        setError(e.error.split('\n'))
        console.log(error)
      })
  }

  return (
    <div className="App">
      <form
        id="contact-form"
        // className="mt-10"
        onSubmit={handleOnClick}
        method="POST"
        action=""
        novalidate
      >
        <div className="form-group">
          {/* <label htmlFor="name">Name</label> */}
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {name.trim().length === 0 && submit && (
            <div style={{ color: 'red', fontSize: 12 }}>Required</div>
          )}
        </div>
        <div className="form-group">
          {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
          <input
            type="tel"
            className="form-control"
            id="phone"
            placeholder="Phone (Eg: +919876543210)"
            aria-describedby="phoneHelp"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          {phone.trim().length === 0 && submit && (
            <div style={{ color: 'red', fontSize: 12 }}>Required</div>
          )}
          {error.some((e) => /phone/g.test(e)) && (
            <div style={{ color: 'red', fontSize: 12 }}>
              Phone number does not match pattern
            </div>
          )}
        </div>
        <div className="form-group">
          {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
          <input
            className="form-control"
            id="email"
            placeholder="Email address"
            aria-describedby="emailHelp"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {email.trim().length === 0 && submit && (
            <div style={{ color: 'red', fontSize: 12 }}>Required</div>
          )}
          {error.some((e) => /email/g.test(e)) && (
            <div style={{ color: 'red', fontSize: 12 }}>
              Email address does not match pattern
            </div>
          )}
        </div>

        <div className="form-group">
          {/* <label htmlFor="message">Message</label> */}
          <textarea
            className="form-control"
            rows="2"
            id="message"
            placeholder="Message/ Complaint"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Upload Document</label>
          <div className="upload-doc">
            <label htmlFor="filePicker">
              <FontAwesomeIcon icon={faUpload} />
              &nbsp;Select File
            </label>
          </div>
          <span id="fileName"></span>

          <input
            id="filePicker"
            className="file_btn"
            onChange={(e) => fileSelected(e.target.files)}
            type="file"
            style={{ visibility: 'hidden' }}
          />
        </div>
        <button
          disabled={loading}
          type="submit"
          className="btn"
          style={{ marginBottom: 24, background: '#4AB7FF', color: 'white' }}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  )
}

// class ContactForm extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: '',
//       email: '',
//       message: '',
//       isVerified: false,
//       contactUs: [],
//     };
//   }

//   recaptchaLoader() {
//     console.log('Recaptcha is Loaded');
//   }
//   verifyCallback(response) {
//     if (response) {
//       this.setState({
//         isVerified: true,
//       });
//     }
//   }
//   render() {

//   }

//   onNameChange(event) {
//     this.setState({ name: event.target.value });
//   }

//   onEmailChange(event) {
//     this.setState({ email: event.target.value });
//   }

//   onMessageChange(event) {
//     this.setState({ message: event.target.value });
//   }
// }

export default ContactForm
