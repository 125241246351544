import React, { useEffect, useState } from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import { getServicesBySlug, homePageData } from '../api'
import noimage from '../../img/No-Photo-Available.jpg'
import HeroImg from '../../img/article.jpg'
import { Helmet } from 'react-helmet'
import { Media, Image, Container } from 'react-bootstrap'
import { useData } from '../../contexts/Datacontext'
import LoadScript from 'react-load-script'
import { Link } from 'react-router-dom'
import { ShareAltOutlined, CopyOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { NotificationManager } from 'react-notifications'
import copyImg from '../../img/copy.png'

const Experts = (props) => {
  const [expert, setExperts] = useState({})
  const [expertList, setExpertList] = useState([])
  const { data } = useData()
  const { match } = props
  const { params } = match
  const { id } = params

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    handlePageData()
  }, [])


  const handleAddthisLoaded = () => {
    if (typeof addthis !== 'undefined') {
      setTimeout(() => {
        try {
          // eslint-disable-next-line no-undef
          addthis && addthis.layers?.refresh()
        } catch (e) {}
      }, 1000)
    }
  }

  // const handleAddthisLoaded = setInterval(function () {
  //   if (typeof addthis !== 'undefined') {
  //     clearInterval(handleAddthisLoaded);
  //     // eslint-disable-next-line no-undef
  //     addthis && addthis?.layers.refresh();
  //     console.log('interval');
  //   }
  // }, 1000);

  const handlePageData = async () => {
    const res = await homePageData()
    //const favicon = document.getElementById('favicon')
    //if (favicon) favicon.href = res?.logo?.Logo
  }

  const createMarkup = (data) => {
    return { __html: data }
  }

  useEffect(() => {
    if (
      window.location.search !== undefined &&
      typeof window.location.search !== 'number'
    ) {
      fetchEditionsBySlug(window.location.search.slice(1))
    }
  }, [id])

  const fetchEditionsBySlug = async (value) => {
    const response = await getServicesBySlug(value)
    if (Object.keys(response)) {
      const expertList = response.published_data?.Experts.filter(
        (expert) => expert.slug !== id && expert.status === 'active'
      )
      setExpertList(expertList)
      const currentExpert = response.published_data.Experts.find(
        (expert) => expert.slug === id && expert.status === 'active'
      )
      if (currentExpert) {
        setExperts(currentExpert)
      }
    }
  }

  const handleChange = (expert) => {
    setExperts(expert)
  }

  const refScroller = (data) => {
    if (props.location.pathname === '/home') {
      const section = document.querySelector(`#${data}`)
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      props.history.push({
        pathname: '/',
        state: { detail: data },
      })
    }
  }

  return (
    <>
      <LoadScript
        url="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-60bb86f49dd949d8"
        onLoad={handleAddthisLoaded}
      />
      <Header refScroller={refScroller} data={data?.data} />
      <div className="waxon_tm_blog_single waxon_tm_experts">
        <>
          <div className="hero classic">
            <div
              className="articleImg"
              style={{ backgroundImage: `url(${HeroImg})` }}
            />
          </div>
          <div className="container bigger" style={{ paddingLeft: '0px' }}>
            <div className="row">
              <div
                className="flex-box-item col-11 col-md-5 col-lg-5 mb-4 pb-4"
                style={{
                  backgroundColor: '#f5f6fa',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  height: 'fit-content',
                }}
              >
                <h4 className="ml-2 pt-2">Related Lawyers</h4>
                {expertList?.length > 0 ? (
                  expertList.map((expert, index) => (
                    <Media
                      key={expert.id}
                      style={{
                        marginLeft: '15px',
                        paddingTop: '40px',
                        borderBottom: '2px solid #d3d3d3',
                      }}
                    >
                      <Media left href="#">
                        <>
                          {expert.image ? (
                            <img
                              src={expert.image}
                              alt="img"
                              width="90px"
                              height="80px"
                            />
                          ) : (
                            <img
                              src={noimage}
                              alt="img"
                              width="90px"
                              height="80px"
                            />
                          )}
                        </>
                      </Media>
                      <Media body>
                        <div className="row">
                          <div className="col-12">
                            <Media
                              heading
                              style={{
                                paddingLeft: '10px',
                                fontWeight: 'bold',
                              }}
                            >
                              {expert.title}
                            </Media>
                          </div>
                          <div className="col-12">
                            {/* <div
                              style={{ paddingLeft: "10px" }}
                              dangerouslySetInnerHTML={createMarkup(
                                expert.content
                                  .replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, "")
                                  .slice(0, 50) + "..."
                              )}
                            /> */}
                          </div>
                          <div className="col d-flex">
                            {/* <button
                              onClick={() => handleChange(expert, index)}
                              to={{
                                pathname: `/expert/${expert.slug}`,
                                state: expert.id,
                              }}
                              style={{
                                backgroundColor: "#4ab7ff",
                                marginLeft: "10px",
                                border: "none",
                                marginBottom: "4px",
                                color: "#fff",
                                borderRadius: "12px",
                              }}
                            >
                              Read More
                            </button> */}
                            <Link
                              to={{
                                pathname: `/experts/${expert.slug}`,
                                search: window.location.search.slice(1),
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: '#4ab7ff',
                                  marginLeft: '10px',
                                  border: 'none',
                                  marginBottom: '4px',
                                  color: '#fff',
                                  borderRadius: '12px',
                                }}
                              >
                                View
                              </button>
                            </Link>
                          </div>
                        </div>
                      </Media>
                    </Media>
                  ))
                ) : (
                  <p className="text-center">No Related Lawyers!!</p>
                )}
              </div>
              <div className="col mb-4">
                {/* <div className="short_info">
                  <ul>
                    <li>
                      <div className="list_inner">
                        <img
                          className="svg"
                          src={require("../../img/svg/calendar.svg").default}
                          alt="calender"
                        />
                        <span>{expert.publishing_time}</span>
                      </div>
                    </li>
                  </ul>
                </div> */}
                {expert && (
                  <>
                    <Container>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: '10px',
                        }}
                      >
                        <div>
                          <ShareAltOutlined
                            style={{
                              fontSize: '30px',
                              color: '#08c',
                            }}
                          />{' '}
                          Share With Your Friends
                        </div>
                        {/* <CopyToClipboard
                      text={window.location.href}
                      onCopy={() => {
                        NotificationManager.success('Link Copied')
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <CopyOutlined
                          style={{
                            marginRight: 5,
                            fontSize: '30px',
                            color: '#08c',
                          }}
                        />{' '}
                        Copy Link
                      </div>
                    </CopyToClipboard> */}
                      </div>

                      <div style={{ display: 'flex' }}>
                        <div
                          // props.addThisLoaded
                          class="addthis_inline_share_toolbox"
                          data-url={window.location.href}
                          data-title="Check out this URL"
                        ></div>
                        <CopyToClipboard
                          text={window.location.href}
                          onCopy={() => {
                            NotificationManager.success('Link Copied')
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                              background: '#F7CE6D',
                              height: '50px',
                              width: '100px',
                              marginLeft: '5px',
                            }}
                          >
                            <img
                              src={copyImg}
                              alt="copy_link"
                              height={23}
                              width={23}
                              style={{ marginRight: '10px' }}
                            />{' '}
                            Copy
                          </div>
                        </CopyToClipboard>
                      </div>
                    </Container>

                    <Container>
                      {expert.image && (
                        <div
                          style={{ marginBottom: '10px' }}
                          className="justify-content-center"
                        >
                          <Image
                            src={expert.image}
                            style={{ width: '500px', height: '375px' }}
                          />
                        </div>
                      )}

                      {/* <Container> */}
                      <div className="title">
                        <h3>{expert.title}</h3>
                      </div>
                      {/* </Container> */}

                      <div
                        className="blog_content"
                        style={{
                          fontSize: '20px',
                        }}
                      />
                      <div
                        className="blog_content"
                        style={{
                          fontSize: '20px',
                        }}
                        dangerouslySetInnerHTML={createMarkup(expert?.content)}
                      />
                      <h5
                        className="mt-3 mb-2 "
                        dangerouslySetInnerHTML={createMarkup(
                          expert?.description
                        )}
                      />
                      <div class="desktop-number">
                        <div>
                          <span>
                            <b className="mr-2">Phone Number :</b>
                            {expert.call_now}
                          </span>
                        </div>
                        {/* <div>
                          <span>
                            <b className="mr-2">Whatsapp :</b>
                            {expert.whatsapp_no}
                          </span>
                        </div> */}
                        <div></div>
                      </div>
                      <span>
                        <b className="mr-2">Circles :</b>
                        {expert?.circles?.length > 0 &&
                          expert?.circles?.map((cur) => (
                            <span className="tag-text">
                              {' ' + cur.name + ' '}
                            </span>
                          ))}
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            textAlign: 'center',
                            backgroundColor: '#4ab7ff',
                            padding: '4px 8px',
                            border: 'none',
                            borderRadius: '10px',
                            color: '#fff',
                            width: '110px',
                            height: '50px',
                            lineHeight: '40px',
                          }}
                        >
                          <a
                            href="tel://${expert.call_now}"
                            style={{ color: 'white', textDecoration: 'none' }}
                          >
                            <i
                              class="fa fa-phone fa-lg"
                              saria-hidden="true"
                            ></i>{' '}
                            Call Us
                          </a>
                        </div>

                        {/* <div>
                        <span><b className="mr-2">Whatsapp</b>{expert.whatsapp_no}</span>
                      </div> */}
                        {expert?.whatsapp_no && (
                          <div
                            style={{
                              backgroundColor: '#25D366',
                              padding: '4px 8px',
                              border: 'none',
                              borderRadius: '10px',
                              cursor: 'pointer',
                              margin: '10px 2px',
                              color: '#fff',
                              textAlign: 'center',
                              width: '110px',
                              height: '50px',
                              lineHeight: '40px',
                            }}
                          >
                            <a
                              href={`https://api.whatsapp.com/send?phone=${expert.whatsapp_no}&amp;text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202.`}
                              style={{ color: 'white', textDecoration: 'none' }}
                            >
                              WhatsApp
                            </a>
                          </div>
                        )}

                        {expert?.website_url && (
                          <div
                            style={{
                              textAlign: 'center',
                              backgroundColor: '#4ab7ff',
                              padding: '4px 8px',
                              border: 'none',
                              borderRadius: '10px',
                              color: '#fff',
                              width: '110px',
                              height: '50px',
                              lineHeight: '42px',
                            }}
                          >
                            {/* <i class="fa fa-globe"></i> */}
                            <a
                              href={expert.website_url}
                              target="_blank"
                              style={{ color: 'white', textDecoration: 'none' }}
                            >
                              Visit Web
                            </a>
                          </div>
                        )}

                        {expert?.map_url && (
                          <div
                            style={{
                              textAlign: 'center',
                              backgroundColor: '#4ab7ff',
                              padding: '4px 8px',
                              border: 'none',
                              borderRadius: '10px',
                              color: '#fff',
                              width: '110px',
                              height: '50px',
                              lineHeight: '40px',
                              marginLeft: 2,
                            }}
                          >
                            {/* <i class="fa fa-map-marker" aria-hidden="true"></i> */}

                            <a
                              href={expert.map_url}
                              target="_blank"
                              style={{ color: 'white', textDecoration: 'none' }}
                            >
                              Direction
                              {/* <i class="fa fa-arrow-right" saria-hidden="true"></i> */}
                            </a>
                          </div>
                        )}
                      </div>
                    </Container>
                    <Helmet>
                      <title>{expert.metaTitle}</title>
                      <meta name="og:title" content={expert.metaTitle} />
                      <meta
                        name="twitter:title"
                        property="og:title"
                        content={expert.metaTitle}
                      />
                      <meta
                        name="twitter:description"
                        content={expert.metaDescription}
                      />
                      <meta
                        name="og:description"
                        content={expert.metaDescription}
                      />
                      <meta
                        name="description"
                        content={expert.metaDescription}
                      />
                      <meta name="keywords" content={expert.metaKeywords} />
                    </Helmet>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
      <Footer />
    </>
  )
}
export default Experts
